import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Aboutus from "./pages/Aboutus/Aboutus";
import Homepage from "./pages/Homepage/Homepage";
import OurProducts from "./pages/OurProducts/OurProducts";
import OurProjects from "./pages/Projects/Projects";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import ProjectsCategory from "./pages/ProjectsCategory/ProjectsCategory";
import ProjectsDetail from "./pages/ProjectsDetail/ProjectsDetail";
import Blogs from "./pages/Blogs/Blogs";
import BlogDetail from "./pages/BlogDetail/BlogDetail";
import Header from "./components/Header/Header";
import ContactUs from "./pages/ContactUs/ContactUs";
import Faq from "./pages/Faq/Faq";
import Events from "./pages/Events/Events";
import Downloads from "./pages/Downloads/Downloads";
import Thankyou from "./pages/Thankyou/Thankyou";

const DefaultLayout = () => {
  return (
    <>
      <Header />
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route exact path="/about-us" component={Aboutus} />
        <Route exact path="/products" component={OurProducts} />
        <Route exact path="/projects" component={OurProjects} />
        <Route exact path="/projects/:slug" component={ProjectsCategory} />
        <Route
          exact
          path="/projects/:category/:slug"
          component={ProjectsDetail}
        />
        <Route exact path="/blog" component={Blogs} />
        <Route exact path="/blog/:blogSlug" component={BlogDetail} />

        <Route exact path="/contact-us" component={ContactUs} />

        <Route exact path="/faq" component={Faq} />

        <Route exact path="/events" component={Events} />

        <Route exact path="/downloads" component={Downloads} />

        <Route exact path="/thank-you" component={Thankyou} />

        {/* 404 */}
        <Route exact path="/404" component={PageNotFound} />
        <Redirect to="/404"></Redirect>
      </Switch>
      <Footer />
    </>
  );
};

export default DefaultLayout;
