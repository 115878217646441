import React from "react";
import { NavLink } from "react-router-dom";
import "./style.scss";

import useWindowDimensions from "../../hooks/useWindowDimensions";

//images
import {
  facebook,
  linkedin,
  insta,
  backto,
  itimilogo,
  mcmlogo,
  youtube,
} from "../../images";
import { fbpath, instapath, linkedinpath } from "../../helpers/paths";

const Footer = () => {
  const { width } = useWindowDimensions();
  return (
    <>
      <section className="footer">
        <div className="footer_flex">
          {width > 767 ? (
            <>
              <img
                src={backto}
                alt="MCM back to top"
                className="back_to_img"
                loading="lazy"
                onClick={() => window.scrollTo(0, 0)}
              />
              <span
                className="back_to_span"
                onClick={() => window.scrollTo(0, 0)}
              >
                back to top
              </span>
            </>
          ) : null}
          <div className="social_icons_content">
            <h2>Light Weight Façade System.</h2>
            {width > 767 ? (
              <div className="social_icons_wrapper">
                <a
                  href="https://www.facebook.com/mcm.cladding.15/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={facebook}
                    alt="MCM social icons"
                    loading="lazy"
                    className="fb_logo"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/in/mcm-cladding/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={linkedin} alt="MCM social icons" loading="lazy" />
                </a>
                <a
                  href="https://www.instagram.com/mcmcladdingindia/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={insta} alt="MCM social icons" loading="lazy" />
                </a>
                <a href={() => false} className="youtube">
                  <img
                    src={youtube}
                    alt="MCM youtube"
                    loading="lazy"
                    className="youtube_logo"
                  />
                </a>
                {/* <a href={() => false} target="_blank" rel="noreferrer">
                  <img src={twitter} alt="MCM social icons" loading="lazy" />
                </a> */}
              </div>
            ) : null}
          </div>
          {/* {width > 767 ? ( */}
          <ul>
            <li>
              <NavLink
                to="/products"
                activeClassName="footer_link_active"
                className="footer_link"
              >
                products
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/projects"
                activeClassName="footer_link_active"
                className="footer_link"
              >
                projects
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact-us"
                activeClassName="footer_link_active"
                className="footer_link"
              >
                contact us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/downloads"
                activeClassName="footer_link_active"
                className="footer_link"
              >
                downloads
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/faq"
                activeClassName="footer_link_active"
                className="footer_link"
              >
                FAQ's
              </NavLink>
            </li>
          </ul>
          {/* ) : null} */}
          <div className="get_in_touch_wrapper">
            <h4>get in touch</h4>
            <div className="contact_details_wrapper">
              <p className="contact_desc">Ideate. Innovate. Invent</p>
              <p>
                <a href="mailto:info@mcmcladding.com">info@mcmcladding.com</a>{" "}
              </p>
              <p>
                {/* <a href="tel:+91 22 2673 5084">+91 22 2673 5084</a>{" "}
                <span></span> */}
                <a href="tel:+91 7045905068">+91 7045905068</a>
                <br />
                <a href="tel:+91 8291900355">+91 8291900355</a>
              </p>
            </div>
            <p>
              <a href="/">
                <img
                  src={mcmlogo}
                  alt="MCM logo"
                  loading="lazy"
                  className="mcm_logo"
                />
              </a>
              <span className="footer_logos_span"></span>
              {/* <a href="http://139.59.48.12:9006/" target="_blank" rel="noreferrer"> */}
              <a href={() => false} className="footer_image">
                <img
                  src={itimilogo}
                  alt="itimi logo"
                  loading="lazy"
                  className="mcm_logo"
                />
              </a>
            </p>
            {width < 767 ? (
              <div className="social_icons_wrapper">
                <a href={fbpath} target="_blank" rel="noreferrer">
                  <img
                    src={facebook}
                    alt="MCM social icons"
                    loading="lazy"
                    className="fb_logo social_icon_img"
                  />
                </a>
                <a href={linkedinpath} target="_blank" rel="noreferrer">
                  <img
                    src={linkedin}
                    alt="MCM social icons"
                    loading="lazy"
                    className="social_icon_img"
                  />
                </a>
                <a href={instapath} target="_blank" rel="noreferrer">
                  <img
                    src={insta}
                    alt="MCM social icons"
                    loading="lazy"
                    className="social_icon_img"
                  />
                </a>
                <a href={() => false}>
                  <img
                    src={youtube}
                    alt="MCM social icons"
                    loading="lazy"
                    className="social_icon_img"
                  />
                </a>
                {/* <a href={() => false} target="_blank" rel="noreferrer">
                  <img
                    src={twitter}
                    alt="MCM social icons"
                    loading="lazy"
                    className="social_icon_img"
                  />
                </a> */}
              </div>
            ) : null}
          </div>
        </div>
      </section>
      <p className="copyright_text">
        Copyrights MCM Flexi {new Date().getFullYear()} | Crafted by{" "}
        <a href="https://www.togglehead.in/" target="_blank" rel="noreferrer">
          Togglehead
        </a>
      </p>
    </>
  );
};

export default Footer;
